import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';

const resource = `${Config.basePath}/v1/api/admin/fight`;

export default {
  options(payload) {
    return Client.post(resource, {
      ...payload,
      channel_type: 2,
    }, {
      headers: {
        action: "list-active-channel-type",
      },
    });
  },
};
