import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';

const resource = `${Config.basePath}/v1/api/admin/setting`;
const ccy = 116;

export default {
  options(payload) {
    const params = {
      ...payload,
    };
    return Client.post(resource, {
      params: params
    }, {
      headers: {
        action: 'currency-list',
      }
    });
  },
  update(payload) {
    const params = {
      ...payload,
    };
    return Client.post(resource, params, {
      headers: {
        action: 'exchange-rate-update',
      },
    });
  },
  show(payload) {
    const params = {
      ...payload,
    };
    return Client.post(resource, params, {
      headers: {
        action: 'exchange-rate',
      },
    });
  },
};
