export default [
  {
    path: '/request-deposit',
    name: 'request-deposit',
    component: () => import('@/views/requestDeposit/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.requestDeposit',
      resource: 'request-deposit',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listRequestDeposit',
          active: true,
        },
      ],
    },
  },
]
