import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import { prepareSearchParams } from '@/libs/helper';

const resource = `${Config.basePath}/v1/api/admin/report`;

export default {
  index(payload) {
    let params = prepareSearchParams(payload);
    params = {
      ...payload,
      ...params,
      username: payload.search,
    }
    return Client.post(resource, params, {
      headers: {
        action: "balance-report",
      },
    });
  },
  total(payload) {
    let params = prepareSearchParams(payload);
    params = {
      ...payload,
      ...params,
      username: payload.search,
    }
    return Client.post(resource, params, {
      headers: {
        action: "balance-report-total",
      },
    });
  },
  transaction(payload) {
    let params = prepareSearchParams(payload);
    params = {
      ...payload,
      ...params,
      username: payload.search,
    }
    if (payload.startDate) {
      params.filters.push({
        searchby: "created_at",
        searchoperator: ">",
        search: `${payload.startDate} 00:00:00`,
      });
    }
    if (payload.toDate) {
      params.filters.push({
        searchby: "created_at",
        searchoperator: "<",
        search: `${payload.toDate} 23:59:59`,
      });
    }
    if (payload.userId) {
      params.filters.push({
        searchby: "user_id",
        searchoperator: "=",
        search: payload.userId,
      });
    }
    if (payload.stateType) {
      params.filters.push({
        searchby: "state_type",
        searchoperator: "=",
        search: payload.stateType,
      });
    }
    return Client.post(`${resource}`, params, {
      headers: {
        action: "list-transaction-history",
      },
    });
  },
};
