export default [
  {
    path: '/request-withdraw',
    name: 'request-withdraw',
    component: () => import('@/views/requestWithdraw/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.requestWithdraw',
      resource: 'request-withdrawal',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listRequestWithdraw',
          active: true,
        },
      ],
    },
  },
]
