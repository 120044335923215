export default [
  {
    path: '/baccarat-balance-report',
    name: 'baccarat-balance-report',
    component: () => import('@/views/baccaratBalanceReport/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.baccaratBalanceReport',
      resource: 'baccarat-balance-report',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listBaccaratBalanceReport',
          active: true,
        },
      ],
    },
  },
]
