import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';

const resource = `${Config.basePath}/v1/api/admin/auth`;

export default {
  generateCredential() {
    return Client.post(resource, {}, {
      headers: {
        action: "gen-vendor-key",
      },
    });
  },
  updatePassword(payload) {
    const params = {
      ...payload,
    };
    return Client.post(resource, params, {
      headers: {
        action: 'admin-change-password',
      }
    });
  },
  updateProfile(payload) {
    const params = {
      ...payload,
    };
    return Client.post(resource, params, {
      headers: {
        action: 'update-admin-profile',
      }
    });
  },
  getProfile(payload = {}) {
    const params = {
      ...payload,
    };
    return Client.post(resource, {
      params: params
    }, {
      headers: {
        action: 'admin-profile',
      },
    });
  },
};
