import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';

const resource = `${Config.basePath}/v1/api/admin/setting/payment-method`;

export default {
  show() {
    return Client.post(resource, {}, {
      headers: {
        action: "list-payment-method",
      },
    });
  },
  update(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "store-payment-method",
      },
    });
  },
};
