export default [
    {
        path: '/assistant',
        name: 'list-assistant',
        component: () => import('@/views/assistant/List.vue'),
        meta: {
            pageTitle: 'breadcrumb.assistant',
            resource: 'assistant',
            action: 'read',
            breadcrumb: [
                {
                    text: 'breadcrumb.listAssistant',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/assistant/create',
        name: 'create-assistant',
        component: () => import('@/views/assistant/Create.vue'),
        meta: {
            pageTitle: 'breadcrumb.assistant',
            navActiveLink: 'list-assistant',
            resource: 'assistant',
            action: 'create',
            breadcrumb: [
                {
                    text: 'breadcrumb.listAssistant',
                    to: { name: 'list-assistant' }
                },
                {
                    text: 'breadcrumb.createAssistant',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/assistant/:id/update',
        name: 'update-assistant',
        component: () => import('@/views/assistant/Update.vue'),
        meta: {
            pageTitle: 'breadcrumb.assistant',
            navActiveLink: 'list-assistant',
            resource: 'assistant',
            action: 'update',
            breadcrumb: [
                {
                    text: 'breadcrumb.listAssistant',
                    to: { name: 'list-assistant' }
                },
                {
                    text: 'breadcrumb.updateAssistant',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/assistant/:id',
        name: 'view-assistant',
        component: () => import('@/views/assistant/View.vue'),
        meta: {
            pageTitle: 'breadcrumb.assistant',
            navActiveLink: 'list-assistant',
            resource: 'assistant',
            action: 'read',
            breadcrumb: [
                {
                    text: 'breadcrumb.listAssistant',
                    to: { name: 'list-assistant' }
                },
                {
                    text: 'breadcrumb.viewAssistant',
                    active: true,
                },
            ],
        },
    },
]
