import Vue from 'vue';
import moment from 'moment';

Vue.filter('formatDate', function (value, format = 'DD-MM-YYYY HH:mm:ss') {
  if (!value) {
    return "";
  }

  return moment(value).format(format);
});
