import ProfileRepository from "./Api/ProfileRepository";
import CurrencyRepository from "./Api/CurrencyRepository";
import CompanyRepository from "./Api/CompanyRepository";
import SeniorRepository from "./Api/SeniorRepository";
import MasterRepository from "./Api/MasterRepository";
import AgentRepository from "./Api/AgentRepository";
import PlayerRepository from "./Api/PlayerRepository";
import AccountRepository from "./Api/AccountRepository";
import TransactionRepository from "./Api/TransactionRepository";
import BalanceReportRepository from "./Api/BalanceReportRepository";
import DashboardRepository from "./Api/DashboardRepository";
import WithdrawRequestRepository from "./Api/WithdrawRequestRepository";
import DepositRequestRepository from "./Api/DepositRequestRepository";
import DepositSettingRepository from "./Api/DepositSettingRepository";
import BetHistoryRepository from "./Api/BetHistoryRepository";
import BaccaratBalanceReportRepository from "./Api/BaccaratBalanceReportRepository";
import ChannelRepository from "./Api/ChannelRepository";
import ChannelBaccarateRepository from "./Api/ChannelBaccarateRepository";
import ChannelCockRepository from "./Api/ChannelCockRepository";
import AssistantRepository from "./Api/AssistantRepository";

const repositories = {
  'dashboard': DashboardRepository,
  'profile': ProfileRepository,
  'currency': CurrencyRepository,
  'company': CompanyRepository,
  'senior': SeniorRepository,
  'master': MasterRepository,
  'agent': AgentRepository,
  'player': PlayerRepository,
  'account': AccountRepository,
  'transaction': TransactionRepository,
  'balanceReport': BalanceReportRepository,
  'withdrawRequest': WithdrawRequestRepository,
  'depositRequest': DepositRequestRepository,
  'depositSetting': DepositSettingRepository,
  'betHistory': BetHistoryRepository,
  'baccaratBalanceReport': BaccaratBalanceReportRepository,
  'channel': ChannelRepository,
  'channelBaccarate': ChannelBaccarateRepository,
  'channelCock': ChannelCockRepository,
  'assistant': AssistantRepository,
};

export default {
  get: name => repositories[name]
};
