export default [
  {
    path: '/balance-report',
    name: 'balance-report',
    component: () => import('@/views/balanceReport/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.balanceReport',
      resource: 'balance-report',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listBalanceReport',
          active: true,
        },
      ],
    },
  },
]
